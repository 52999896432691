import { Component, Inject, Injector } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatSelectChange } from '@angular/material/select'
import { BaseParamsRequest, DealCreateRequest, LeadCreateRequest, NoteCreateRequest, PersonCreateRequest, PipelineObject, StageListRequest, StageObject, TrendPipedriveAPIService } from '@trendbuild/trend-pipedrive-api'
import { UserObject } from '@trendbuild/trend-pipedrive-api/src/lib/models/users.types'
import { AbstractComponent, Attendance, Integration, IntegrationService, MessageService, PipedriveIntegration } from 'lib-trend-core'

interface ItemOption {
  label: string;
  value: string;
}

@Component({
  selector: 'crm-pipedrive.component.html',
  templateUrl: './crm-pipedrive.component.html',
})
export class CrmPipedriveComponent extends AbstractComponent {
  private trendPipedriveAPIService: TrendPipedriveAPIService = new TrendPipedriveAPIService();

  step: number = 0;
  apiToken: ItemOption[] = []; // Opções exibidas no select
  selectedIntegrationApiToken: string;
  pipelinesOptions: ItemOption[] = [];
  selectedPipeline: string | null = null;
  stagesOptions: ItemOption[] = [];
  usersOptions: ItemOption[] = [];
  selectedStage: string | null = null;
  selectedUser: { label: string; value: number } | null = null;
  dealValue: number | null = null;
  sendMessageHistory: boolean = false;

  constructor(
    injector: Injector,
    private integrationService: IntegrationService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public attendance: Attendance,
    private matDialogRef: MatDialogRef<CrmPipedriveComponent>
  ) {
    super(injector)
    this.formGroup = this.formBuilder.group({
      sendMessageHistory: [false],
    })
  }

  ngOnInit() {
    this.loadIntegrations();
  }

  override ngOnDestroy() {
    this.loadUsersOptions();
  }

  onPipelineSelection(event: MatSelectChange) {
    const selectElement = event.value;
    this.apiToken = selectElement;
  }

  usersSelection(event: MatSelectChange) {
    const selectElement = event.value;
    this.selectedUser = selectElement;
  }

  integrationSelection(event: MatSelectChange) {
    this.selectedIntegrationApiToken = event.value;

    if (this.selectedIntegrationApiToken) {
      this.loadUsersOptions();
      this.loadPipelines();
    }
  }

  async loadIntegrations() {
    try {
      const companyId = this.attendance?.company?._id || this.attendance?.company;
      if (!companyId) {
        this.alertService.error('Id da company inexistente');
        return;
      }

      const response = await this.integrationService.getAll(1, 5).toPromise();

      const filteredList = response.list.filter(
        (item: Integration) =>
          item.type === 'PIPEDRIVE' && item.actived && item.removed === false
      );

      if (!filteredList || filteredList.length === 0) {
        this.alertService.warning(
          'Nenhuma integração ativa do Pipedrive encontrada para esta empresa.'
        );
        return;
      }

      this.apiToken = filteredList.map((integration: Integration) => ({
        label: integration.name,
        value: integration.metadata['api_token'],
      }));
    } catch (error) {
      console.error('Erro ao carregar integrações:', error);
      this.alertService.error('Erro ao buscar dados de integração do Pipedrive.');
      return;
    }
  }

  async loadUsersOptions() {
    try {
      const companyId = this.attendance?.company?._id || this.attendance?.company;
      if (!companyId) {
        this.alertService.error('Id da company inexistente');
        return;
      }

      const integration = this.apiToken.find((token) => token.value === this.selectedIntegrationApiToken);

      if (!integration) {
        this.alertService.error('Integração não encontrada.');
        return;
      }

      const baseParamsRequest: BaseParamsRequest = {
        api_token: integration.value,
      };

      const users = await this.trendPipedriveAPIService.listUsers(
        baseParamsRequest
      );

      if (!users.success || !users.data) {
        this.alertService.error('Erro ao buscar usuários do Pipedrive.');
        this.matDialogRef.close();
        return;
      }

      const activeUsers = users.data.filter((user: UserObject) => user.active_flag);

      this.usersOptions = activeUsers.map((user: UserObject) => ({
        label: user.name,
        value: String(user.id),
      }));
    } catch (error) {
      console.error('Error loading user options:', error);
      this.alertService.error('Erro ao buscar dados de integração do Pipedrive.');
    }
  }

  async loadPipelines() {
    try {

      const pipelinesResult = await this.trendPipedriveAPIService.listPipelines(
        {
          api_token: this.apiToken.find((token) => token.value === this.selectedIntegrationApiToken)
            ?.value || '',
        }
      );

      if (pipelinesResult.success && pipelinesResult.data) {
        this.pipelinesOptions = pipelinesResult.data.map((pipeline: PipelineObject) => ({
          value: pipeline.id.toString(),
          label: pipeline.name,
        }));
      }
    } catch (error) {
      console.error('Error loading pipelines:', error);
      this.alertService.error('Erro ao carregar pipelines');
    }
  }

  async stageSelect() {
    if (!this.selectedPipeline) {
      this.stagesOptions = [];
      return;
    }

    try {

      const stageListRequest: StageListRequest = {
        api_token: this.apiToken.find((token) => token.value === this.selectedIntegrationApiToken)
          ?.value || '',
        pipeline_id: parseInt(this.selectedPipeline),
      };

      const stagesResult = await this.trendPipedriveAPIService.listStages(
        stageListRequest
      );

      if (stagesResult.success && stagesResult.data) {
        this.stagesOptions = stagesResult.data.map((stage: StageObject) => ({
          value: String(stage.id),
          label: stage.name,
        }));
      }
    } catch (error) {
      console.error('Error loading stages:', error);
      this.alertService.error('Erro ao carregar estágios');
    }
  }

  async sendDataPersonDeal() {
    const historyMessage = this.formGroup.get('sendMessageHistory')?.value;
    const contactName = this.attendance?.contact?.name || 'Cliente Desconhecido';
    const contactPhone = this.attendance?.contact?.phone || '11999999999';

    if (!this.selectedUser || !this.selectedPipeline || !this.selectedStage) {
      this.alertService.warning('Por favor, selecione todas os campos.');
      return
    }

    this.loading = true
    const personCreateRequest: PersonCreateRequest = {
      api_token:
        this.apiToken.find((token) => token.value === this.selectedIntegrationApiToken)
          ?.value || '',
      name: contactName,
      phone: [{ value: contactPhone }],
      owner_id: Number(this.selectedUser.value),
    };

    const dealCreateRequest: DealCreateRequest = {
      api_token:
        this.apiToken.find((token) => token.value === this.selectedIntegrationApiToken)
          ?.value || '',
      title: `${this.attendance?.contact?.name || 'Contato Sem Nome'}`,
      pipeline_id: parseInt(this.selectedPipeline),
      stage_id: parseInt(this.selectedStage),
      user_id: this.selectedUser.value,
    };

    try {
      let noteCreateRequest: NoteCreateRequest | null = null;

      if (historyMessage) {
        const messages = await this.messageService
          .getByAttendance(this.attendance._id)
          .toPromise();

        const messageHistory = messages
          .map((msg) => {
            const sender =
              !msg.user && !msg.automated
                ? `<b>${this.attendance?.contact?.name}</b>`
                : `<b>Plataforma</b>`

            return `${sender}: ${msg.content}\n`
          })
          .join('<br>');

        noteCreateRequest = {
          api_token:
            this.apiToken.find(
              (token) => token.value === this.selectedIntegrationApiToken
            )?.value || '',
          content: `Histórico de mensagens:<br><br>${messageHistory}`,
          user_id: String(this.selectedUser.value),
          add_time: new Date().toISOString().split('.')[0].replace('T', ' '),
        };

        const result = await this.trendPipedriveAPIService.createPersonAndDeal(
          personCreateRequest,
          dealCreateRequest,
          noteCreateRequest
        );

        if (result) {
          this.alertService.success('Dados com histórico enviados com sucesso!')
          this.step = 2
          this.loading = false
        } else {
          this.loading = false
          this.alertService.warning('Houve um problema ao criar o Lead com histórico.')
        }
      } else {
        const result = await this.trendPipedriveAPIService.createPersonAndDeal(
          personCreateRequest,
          dealCreateRequest
        );

        if (result) {
          this.alertService.success('Dados enviados com sucesso!')
          this.step = 2
          this.loading = false
        } else {
          this.loading = false
          this.alertService.warning('Houve um problema ao criar o Lead sem histórico.')
        }
      }
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
      this.alertService.error('Falha ao processar e enviar os dados.');
    }
  }

  async sendDataPersonLead() {
    const historyMessage = this.formGroup.get('sendMessageHistory')?.value;
    const contactName = this.attendance?.contact?.name || 'Cliente Desconhecido';
    const contactPhone = this.attendance?.contact?.phone || '11999999999';

    if (!this.selectedUser) {
      this.alertService.warning(
        'Por favor, selecione um responsável antes de enviar.'
      );
      return;
    }

    this.loading = true
    const personCreateRequest: PersonCreateRequest = {
      api_token:
        this.apiToken.find((token) => token.value === this.selectedIntegrationApiToken)
          ?.value || '',
      name: contactName,
      phone: [{ value: contactPhone }],
      owner_id: Number(this.selectedUser.value),
    };


    const leadCreateRequest: LeadCreateRequest = {
      api_token:
        this.apiToken.find((token) => token.value === this.selectedIntegrationApiToken)
          ?.value || '',
      title: `${contactName}`,
      owner_id: Number(this.selectedUser.value),
    };


    const noteCreateRequest: NoteCreateRequest = {
      api_token:
        this.apiToken.find((token) => token.value === this.selectedIntegrationApiToken)
          ?.value || '',
      content: '',
      user_id: String(this.selectedUser.value),
      add_time: new Date().toISOString().split('.')[0].replace('T', ' '),
    };

    try {
      if (historyMessage) {
        const messages = await this.messageService.getByAttendance(this.attendance._id).toPromise();

        const messageHistory = messages
          .map((msg) => {
            const sender =
              !msg.user && !msg.automated
                ? `<b>${this.attendance?.contact?.name}</b>`
                : `<b>Plataforma</b>`

            return `${sender}: ${msg.content}\n`
          })
          .join('<br>');

        noteCreateRequest.content = `Histórico de mensagens:<br><br>${messageHistory}`

        const result = await this.trendPipedriveAPIService.createPersonAndLead(
          personCreateRequest,
          leadCreateRequest,
          noteCreateRequest
        )

        if (result) {
          this.alertService.success('Dados com histórico enviados com sucesso!')
          this.step = 2
          this.loading = false
        } else {
          this.loading = false
          this.alertService.warning('Houve um problema ao criar o Lead.')
        }
      } else {
        const result = await this.trendPipedriveAPIService.createPersonAndLead(
          personCreateRequest,
          leadCreateRequest
        )

        if (result) {
          this.alertService.success('Dados enviados com sucesso!')
          this.step = 2
          this.loading = false
        } else {
          this.loading = false
          this.alertService.warning('Houve um problema ao criar o Lead.')
        }
      }
    } catch (error) {
      console.error('Erro ao enviar dados:', error)
      this.alertService.error('Falha ao processar e enviar os dados.')
    }
  }

  async sendDataPerson() {
    const historyMessage = this.formGroup.get('sendMessageHistory')?.value
    const contactName = this.attendance?.contact?.name || 'Cliente Desconhecido'
    const contactPhone = this.attendance?.contact?.phone || '11999999999'

    if (!this.selectedUser) {
      this.alertService.warning(
        'Por favor, selecione um responsável antes de enviar.'
      )
      return
    }

    this.loading = true
    const personCreateRequest: PersonCreateRequest = {
      api_token:
        this.apiToken.find((token) => token.value === this.selectedIntegrationApiToken)
          ?.value || '',
      name: contactName,
      phone: [{ value: contactPhone }],
      owner_id: Number(this.selectedUser.value),
    }

    try {
      if (historyMessage) {
        const createPerson = await this.trendPipedriveAPIService.createPersonId(
          personCreateRequest
        );

        const noteCreateRequest: NoteCreateRequest = {
          api_token:
            this.apiToken.find(
              (token) => token.value === this.selectedIntegrationApiToken
            )?.value || '',
          content: '',
          user_id: String(this.selectedUser.value),
          person_id: Number(createPerson.data.id),
          add_time: new Date().toISOString().split('.')[0].replace('T', ' '),
        };

        const messages = await this.messageService
          .getByAttendance(this.attendance._id)
          .toPromise()

        const messageHistory = messages
          .map((msg) => {
            const sender =
              !msg.user && !msg.automated
                ? `<b>${this.attendance?.contact?.name}</b>`
                : `<b>Plataforma</b>`

            return `${sender}: ${msg.content}\n`
          })
          .join('<br>')

        noteCreateRequest.content = `Histórico de mensagens:<br><br>${messageHistory}`

        const result = await this.trendPipedriveAPIService.createNote(
          noteCreateRequest
        )

        if (result) {
          this.alertService.success('Dados com histórico enviados com sucesso!')
          this.step = 2
          this.loading = false
        } else {
          this.loading = false
          this.alertService.warning('Houve um problema ao criar o Lead.')
        }
      } else {
        const result = await this.trendPipedriveAPIService.createPersonId(
          personCreateRequest
        )

        if (result) {
          this.alertService.success('Dados enviados com sucesso!')
          this.step = 2
          this.loading = false
        } else {
          this.loading = false
          this.alertService.warning('Houve um problema ao criar o Lead.')
        }
      }
    } catch (error) {
      console.error('Erro ao enviar dados:', error)
      this.alertService.error('Falha ao processar e enviar os dados.')
    }
  }

  async sendDataDeal() {
    const historyMessage = this.formGroup.get('sendMessageHistory')?.value

    if (!this.selectedPipeline || !this.selectedStage || !this.selectedUser) {
      this.alertService.warning('Por favor, preencha todos os campos')
      return
    }

    this.loading = true
    const companyId = this.attendance?.company?._id || this.attendance?.company
    const params = {
      filters: {
        company: companyId,
        type: 'PIPEDRIVE',
        actived: true,
        removed: false,
      },
    }

    const response = await this.integrationService.getAll(1, 1, '', params).toPromise();
    const apiToken = (<PipedriveIntegration>response.list[0].metadata)?.api_token

    const dealCreateRequest: DealCreateRequest = {
      api_token: apiToken,
      title: `${this.attendance?.contact?.name || 'Contato Sem Nome'}`,
      pipeline_id: parseInt(this.selectedPipeline),
      stage_id: parseInt(this.selectedStage),
      user_id: this.selectedUser.value,
      value: this.dealValue || 0,
      expected_close_date: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0],
    }

    try {
      if (historyMessage) {
        const result = await this.trendPipedriveAPIService.createDealId(
          dealCreateRequest
        )

        const noteCreateRequest: NoteCreateRequest = {
          api_token:
            this.apiToken.find(
              (token) => token.value === this.selectedIntegrationApiToken
            )?.value || '',
          content: '',
          user_id: String(this.selectedUser.value),
          deal_id: Number(result.data.id),
          add_time: new Date().toISOString().split('.')[0].replace('T', ' '),
        }

        const messages = await this.messageService
          .getByAttendance(this.attendance._id)
          .toPromise()

        const messageHistory = messages
          .map((msg) => {
            const sender =
              !msg.user && !msg.automated
                ? `<b>${this.attendance?.contact?.name}</b>`
                : `<b>Plataforma</b>`

            return `${sender}: ${msg.content}\n`
          })
          .join('<br>')

        noteCreateRequest.content = `Histórico de mensagens:<br><br>${messageHistory}`

        const nota = await this.trendPipedriveAPIService.createNote(
          noteCreateRequest
        )

        if (nota) {
          this.alertService.success('Dados com histórico enviados com sucesso!')
          this.step = 2
          this.loading = false
        } else {
          this.loading = false
          this.alertService.warning('Houve um problema ao criar o Lead.')
        }
      } else {
        const result = await this.trendPipedriveAPIService.createDealId(
          dealCreateRequest
        )

        if (result) {
          this.alertService.success('Dados com histórico enviados com sucesso!')
          this.step = 2
          this.loading = false
        } else {
          this.loading = false
          this.alertService.warning('Houve um problema ao criar o Lead.')
        }
      }
    } catch (error) {
      console.error('Erro ao enviar dados:', error)
      this.alertService.error('Falha ao processar e enviar os dados.')
    }
  }

  nextStepLeads() {
    this.step = 1
  }

  nextStepLeadsTwo() {
    this.step = 2
  }

  nextStepLeadsThree() {
    this.step = 3
  }

  nextStepLeadsFour() {
    this.step = 4
  }

  nextStepLeadsFive() {
    this.step = 5
  }

  nextStepLeadsSix() {
    this.step = 6
  }

  clearIntegrationBack() {
    this.step = 0
  }

  clearValuesToBack() {
    this.step = 2
  }
}
