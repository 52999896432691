import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AbstractComponent, Attendance, AttendanceService, AttendanceStatusEnum, Channel, ChannelService, ContactEditComponent, Department, DepartmentService, LoadMessageService, Message, Pager, SocketService, TabService, Tag, TagService, User, UserService } from 'lib-trend-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, of, switchMap, takeUntil } from 'rxjs';
import { ContactNewConversationComponent } from '../attendance-panel-components/new-conversation/new-conversation.component';
import { getAttendance, getAttendances, getCounts, openCloseSidebarRight, setAttendance, setMessages } from '../state/actions';
import { AppState, CountState, PagerParamsState } from '../state/app.state';
import { attendancesSelector, countsSelector } from '../state/selectors';

@Component({
  selector: 'attendance-panel-list-component',
  templateUrl: 'attendance-panel-list.component.html',
  styleUrls: ['./attendance-panel-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttendancePanelList extends AbstractComponent implements OnInit, OnDestroy {

  public pager$: Observable<Pager<Attendance>> = this.store.select(attendancesSelector);
  public counts$: Observable<Array<CountState>> = this.store.select(countsSelector);

  @ViewChild('btnUpdateAttendancesFromSocket') btnUpdateAttendancesFromSocket: ElementRef;
  @ViewChild('searchBox') searchBox: ElementRef<HTMLInputElement>;

  selectedIndex: number = 0;
  DEFAULT_PER_PAGE_ATTENDANCES: number = Number(this.environment['defaultPerPageAttendances']);
  pager: Pager<Attendance> = new Pager<Attendance>({ perPage: this.DEFAULT_PER_PAGE_ATTENDANCES });
  listChannel: Array<Channel>;
  listTag: Array<Tag>
  listDepartment: Array<Department>
  listUser: Array<User>

  selectedDepartmentIds: string[] = [];
  selectedChannelIds: string[] = [];
  selectedTagIds: string[] = [];
  selectedUserIds: string[] = [];

  labelDepartment: string = 'Departamentos';
  labelChannel: string = 'Canais';
  labelTag: string = 'Tags';

  private notificationAudio = new Audio('../../../../../../../assets/audio/alert-message.mp3');
  private listObservable: Observable<void>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  overtimeCount: number = 0;
  pendingCount: number = 0;
  inProgressCount: number = 0;
  pausedCount: number = 0;
  closedCount: number = 0;
  attendance: Attendance;

  pagerParamsState: PagerParamsState;
  idAttendanceParam!: string;

  loadingMore: boolean = false;
  tmpAttendanceFromSocket: Attendance;

  blockSocketWhenFilterIsActive: boolean = false;
  pagerMessage: Pager<Message> = new Pager<Message>({ perPage: 10 });

  constructor(
    injector: Injector,
    private store: Store<AppState>,
    private loadMessageService: LoadMessageService,
    private tabService: TabService,
    public dialog: MatDialog,
    public socketService: SocketService,
    public attendanceService: AttendanceService,
    private channelService: ChannelService,
    private tagService: TagService,
    private departmentService: DepartmentService,
    private userService: UserService,
  ) {
    super(injector);
    this.createForm();
    this.getAttendanceByParam();
  }

  ngOnInit(): void {
    this.formGroup.controls['status'].valueChanges.subscribe(() => {
      this.getList();
    });

    this.getList();
    this.setupObservableSearch();

    this.tabService.indexTab$.subscribe((index) => {
      this.selectedIndex = index;
    });

    this.formGroup.controls['department'].valueChanges.subscribe((department: Department | null) => {
      this.searchByDepartment(department);
    });

    this.formGroup.controls['channel'].valueChanges.subscribe((channel: Channel | null) => {
      this.searchByChannel(channel);
    });

    this.formGroup.controls['tags'].valueChanges.subscribe((tag: Tag | null) => {
      this.searchByTag(tag);
    });

    this.getListChannel();
    this.getListDepartment();
    this.getListTag();
    this.getListUser();

    this.pager$
      .pipe(takeUntil(this.destroy$))
      .subscribe((pager: Pager<Attendance>) => {
        if (this.loadingMore) {
          this.pager = { ...this.pager, page: pager.page, total: pager.total, perPage: pager.perPage, list: [...this.pager.list, ...pager.list] };
          this.loadingMore = false;
        } else {
          this.pager = pager;
        }
        this.loading = false;
      });

    this.counts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((counts: Array<CountState>) => {
        this.loading = false;
        this.setValuesCountsAttendance(counts);
      });

    this.loadMessageService.eventLoadMessage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.loading = result;
      });

    this.configSocket();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getAttendanceByParam(): void {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      const status = params['status'];

      this.idAttendanceParam = id;

      if (id) {
        this.store.dispatch(getAttendance({ idAttendance: id }));

        switch (!!status) {
          case status === AttendanceStatusEnum.PENDING: {
            this.setTabIndexAndStatus(1, status);
            break;
          }
          case status === AttendanceStatusEnum.IN_PROGRESS: {
            this.setTabIndexAndStatus(2, status);
            break;
          }
          case status === AttendanceStatusEnum.PAUSED: {
            this.setTabIndexAndStatus(3, status);
            break;
          }
          case status === AttendanceStatusEnum.CLOSED: {
            this.setTabIndexAndStatus(4, status);
            break;
          }
          default: {
            this.setTabIndexAndStatus(0, status);
            break;
          }
        }
      }
    });
  }

  private setTabIndexAndStatus(index: number, status: AttendanceStatusEnum): void {
    this.tabService.selectedTabIndex(index);
    this.formGroup.get('status').setValue(status, { emitEvent: false });
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      status: [AttendanceStatusEnum.OVERTIME],
      department: [[]],
      channel: [[]],
      tags: [[]],
    });
  }

  private setupObservableSearch() {
    this.listObservable = this.termoDaBusca.pipe(
      takeUntil(this.destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(term => {
        this.loading = true;
        this.store.dispatch(getCounts({ search: term, params: this.searchParams }));
        this.store.dispatch(getAttendances({ page: this.pager.page, perPage: this.pager.perPage, search: term, params: this.searchParams }));
        return of(null);
      })
    );

    this.listObservable.subscribe({
      next: () => {
        this.loading = false;
        this.updateBlockSocketFlag();
      },
      error: (error) => {
        this.loading = false;
      }
    });
  }

  search(termo: string) {
    this.loading = true;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
    this.updateBlockSocketFlag();
  }

  private updateBlockSocketFlag(): void {
    this.blockSocketWhenFilterIsActive = (
      this.selectedDepartmentIds.length > 0 ||
      this.selectedChannelIds.length > 0 ||
      this.selectedTagIds.length > 0 ||
      this.selectedUserIds.length > 0 ||
      (!!this.searchString)
    );
  }

  private filterData() {
    this.loading = true;
    this.searchParams = {
      company: this.getIDCurrentCompany(),
      status: this.formGroup.get('status')?.value,
      department: this.selectedDepartmentIds,
      channel: this.selectedChannelIds,
      tags: this.selectedTagIds,
      user: this.selectedUserIds,
    };
    this.updateBlockSocketFlag();
    this.store.dispatch(getCounts({ search: this.searchString, params: this.searchParams }));
    this.store.dispatch(getAttendances({
      page: this.pager.page,
      perPage: this.pager.perPage,
      search: this.searchString,
      params: this.searchParams,
    }));

  }

  searchByDepartment(department: Department | null): void {
    if (department) {
      if (this.selectedDepartmentIds.includes(department._id)) {
        this.selectedDepartmentIds = this.selectedDepartmentIds.filter(id => id !== department._id);
      } else {
        this.selectedDepartmentIds = [...this.selectedDepartmentIds, department._id];
      }
    } else {
      this.selectedDepartmentIds = [];
    }
    this.filterData();
  }

  searchByChannel(channel: Channel | null): void {
    if (channel) {
      if (this.selectedChannelIds.includes(channel._id)) {
        this.selectedChannelIds = this.selectedChannelIds.filter(id => id !== channel._id);
      } else {
        this.selectedChannelIds = [...this.selectedChannelIds, channel._id];
      }
    } else {
      this.selectedChannelIds = [];
    }
    this.filterData();
  }

  searchByTag(tag: Tag | null): void {
    if (tag) {
      if (this.selectedTagIds.includes(tag._id)) {
        this.selectedTagIds = this.selectedTagIds.filter(id => id !== tag._id);
      } else {
        this.selectedTagIds = [...this.selectedTagIds, tag._id];
      }
    } else {
      this.selectedTagIds = [];
    }
    this.filterData();
  }

  searchByUser(user: User | null): void {
    if (user) {
      if (this.selectedUserIds.includes(user._id)) {
        this.selectedUserIds = this.selectedUserIds.filter(id => id !== user._id);
      } else {
        this.selectedUserIds = [...this.selectedUserIds, user._id];
      }
    } else {
      this.selectedUserIds = [];
    }
    this.filterData();
  }

  clearFilters(): void {
    this.selectedDepartmentIds = [];
    this.selectedChannelIds = [];
    this.selectedUserIds = [];
    this.selectedTagIds = [];
    this.searchString = '';

    if (this.searchBox && this.searchBox.nativeElement) {
      this.searchBox.nativeElement.value = '';
    }

    this.filterData();
  }

  private getList(): void {
    this.loading = true;

    this.searchParams = {
      company: this.getIDCurrentCompany(),
      status: this.formGroup.get('status')?.value,
      department: this.selectedDepartmentIds,
      channel: this.selectedChannelIds,
      tags: this.selectedTagIds,
      user: this.selectedUserIds,
    };

    this.updateBlockSocketFlag();

    this.store.dispatch(getCounts({ search: this.searchString, params: this.searchParams }));
    this.store.dispatch(getAttendances({
      page: this.pager.page,
      perPage: this.pager.perPage,
      search: this.searchString,
      params: this.searchParams,
    }));
  }

  getListChannel() {
    this.channelService.getList().subscribe({
      next: (list: Array<Channel>) => {
        this.listChannel = list;
      },
      error: (err) => {
        this.alertService.error(err.error.message);
      },
    });
  }

  getListTag() {
    this.tagService.getList().subscribe({
      next: (list: Array<Tag>) => {
        this.listTag = list;
      },
      error: (err) => {
        this.alertService.error(err.error.message);
      },
    });
  }

  getListDepartment() {
    this.departmentService.getList().subscribe({
      next: (list: Array<Department>) => {
        this.listDepartment = list;
      },
      error: (err) => {
        this.alertService.error(err.error.message);
      },
    });
  }

  getListUser() {
    this.userService.getList().subscribe({
      next: (list: Array<User>) => {
        this.listUser = list;
      },
      error: (err) => {
        this.alertService.error(err.error.message);
      },
    });
  }

  changeTab(event: any) {
    this.pager = new Pager<Attendance>({ perPage: this.DEFAULT_PER_PAGE_ATTENDANCES });
    let newStatus = AttendanceStatusEnum.OVERTIME;
    switch (event.index) {
      case 1:
        newStatus = AttendanceStatusEnum.PENDING;
        break;
      case 2:
        newStatus = AttendanceStatusEnum.IN_PROGRESS;
        break;
      case 3:
        newStatus = AttendanceStatusEnum.PAUSED;
        break;
      case 4:
        newStatus = AttendanceStatusEnum.CLOSED;
        break;
      default:
        newStatus = AttendanceStatusEnum.OVERTIME;
    }
    this.formGroup.get('status').setValue(newStatus, { emitEvent: false });
    this.filterData();
  }

  addContact(): void {
    this.dialog.open(ContactEditComponent, {
      width: '600px'
    });
  }

  startNewChat(): void {
    this.dialog.open(ContactNewConversationComponent, {
      width: '70rem',
      minHeight: '25rem',
      maxHeight: '90rem',
    });
  }

  selectAttendance(attendance: Attendance): void {
    if (!!this.idAttendanceParam) {
      this.router.navigate([], {
        queryParams: { id: null, status: null },
        queryParamsHandling: 'merge',
        replaceUrl: true
      })
      this.idAttendanceParam = null;
    }

    if (this.attendance?._id !== attendance._id) {
      attendance = { ...attendance, countUnreadMessages: 0 };
      this.attendance = attendance;
      this.store.dispatch(setMessages({ messages: new Pager<Message>() }));
      this.store.dispatch(setAttendance({ attendance: this.attendance }));
      this.store.dispatch(openCloseSidebarRight({ sidebarRight: false }));
      this.loadMessageService.setLoadingMessage(true);
    }
  }

  private setValuesCountsAttendance(counts: Array<{ count: number, status: AttendanceStatusEnum }>): void {
    this.overtimeCount = counts?.find((value) => value.status === AttendanceStatusEnum.OVERTIME)?.count || 0;
    this.pendingCount = counts?.find((value) => value.status === AttendanceStatusEnum.PENDING)?.count || 0;
    this.inProgressCount = counts?.find((value) => value.status === AttendanceStatusEnum.IN_PROGRESS)?.count || 0;
    this.pausedCount = counts?.find((value) => value.status === AttendanceStatusEnum.PAUSED)?.count || 0;
    this.closedCount = counts?.find((value) => value.status === AttendanceStatusEnum.CLOSED)?.count || 0;
  }

  showAddContact(): boolean {
    return this.getCurrentUserUser().addLeads;
  }

  updateAttendancesFromSocket() {
    const selectedStatus = this.formGroup.get('status').value;
    const indexAttendance = this.pager.list?.findIndex((value) => value._id === this.tmpAttendanceFromSocket?._id) ?? -1;
    if (selectedStatus === this.tmpAttendanceFromSocket.status || indexAttendance >= 0) {

      /**
       * Just will call if the status of the attendance is the same as the status selected in the filter
       */
      let page = this.pager.page;
      let perPage = this.pager.perPage;
      let listSize = this.pager.list.length ?? 0;
      if (listSize > perPage) {
        page = 1;
        perPage = listSize;
      }
      this.store.dispatch(getAttendances({ page, perPage, search: this.searchString, params: this.searchParams }));

      const audioState = localStorage.getItem('audioState');
      const isAudioOn = audioState !== null ? JSON.parse(audioState) : true;
      const currentUserId = this.getIDCurrentUser();
      const assignedUserId = this.tmpAttendanceFromSocket.user?._id;
      if (currentUserId === assignedUserId && isAudioOn) {
        if (!this.attendance || this.attendance._id !== this.tmpAttendanceFromSocket._id) {
          this.notificationAudio.play();
        }
      }
    }
  }

  private configSocket() {
    const code = super.getCodeCompanyCurrentUser();
    const sname = `event_attendances_${code}`;
    const idUser = super.getIDCurrentUser();
    const isAdmin = super.isAdmin();
    const isSupervisor = super.isSupervisor();
    const isAttendant = super.isAttendant();
    const departmenets = super.getIDDepartmentsUserLogged();
    this.socketService.listen(sname)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (item: { attendance: Attendance, attendances: Array<Attendance> }) => {
          if (!this.blockSocketWhenFilterIsActive) {
            this.tmpAttendanceFromSocket = item.attendance;

            // when is admin
            if (isAdmin) {
              this.overtimeCount = item.attendances?.filter((value) => value.status === AttendanceStatusEnum.OVERTIME).length || 0;
              this.pendingCount = item.attendances?.filter((value) => value.status === AttendanceStatusEnum.PENDING).length || 0;
              this.inProgressCount = item.attendances?.filter((value) => value.status === AttendanceStatusEnum.IN_PROGRESS).length || 0;
              this.pausedCount = item.attendances?.filter((value) => value.status === AttendanceStatusEnum.PAUSED).length || 0;
              this.closedCount = item.attendances?.filter((value) => value.status === AttendanceStatusEnum.CLOSED).length || 0;
            } else if (isSupervisor) {
              this.overtimeCount = item.attendances?.filter((value) => value.status === AttendanceStatusEnum.OVERTIME && departmenets.includes(String(value.department))).length || 0;
              this.pendingCount = item.attendances?.filter((value) => value.status === AttendanceStatusEnum.PENDING && departmenets.includes(String(value.department))).length || 0;
              this.inProgressCount = item.attendances?.filter((value) => value.status === AttendanceStatusEnum.IN_PROGRESS && departmenets.includes(String(value.department))).length || 0;
              this.pausedCount = item.attendances?.filter((value) => value.status === AttendanceStatusEnum.PAUSED && departmenets.includes(String(value.department))).length || 0;
              this.closedCount = item.attendances?.filter((value) => value.status === AttendanceStatusEnum.CLOSED && departmenets.includes(String(value.department))).length || 0;
            } else if (isAttendant) {

              this.overtimeCount = item.attendances?.filter((value) => (
                value.status === AttendanceStatusEnum.OVERTIME
                && departmenets.includes(String(value.department))
                && ((!!value.user && idUser === String(value.user)) || (!value.user))
              )).length || 0;

              this.pendingCount = item.attendances?.filter((value) => (
                value.status === AttendanceStatusEnum.PENDING
                && departmenets.includes(String(value.department))
                && ((!!value.user && idUser === String(value.user)) || (!value.user))
              )).length || 0;

              this.inProgressCount = item.attendances?.filter((value) => (
                value.status === AttendanceStatusEnum.IN_PROGRESS
                && (idUser === String(value.user))
              )).length || 0;

              this.pausedCount = item.attendances?.filter((value) => (
                value.status === AttendanceStatusEnum.PAUSED
                && (idUser === String(value.user))
              )).length || 0;

              this.closedCount = item.attendances?.filter((value) => (
                value.status === AttendanceStatusEnum.CLOSED
                && (idUser === String(value.user))
              )).length || 0;

            }
            this.btnUpdateAttendancesFromSocket.nativeElement.click();
          }
        }
      });
  }

  onScroll(event: Event): void {
    // const buffer = 400;
    // const target = event.target as HTMLElement;
    // if (target.scrollHeight - target.scrollTop <= target.clientHeight + buffer) {
    //   if (this.loading) return;

    //   clearTimeout(this.debounceTimer);
    //   this.debounceTimer = setTimeout(() => {
    //     this.loadMoreContacts();
    //   })
    // }
  }

  loadMoreContacts(): void {
    if (this.pager.list.length < this.pager.total) {
      this.loading = true;
      this.loadingMore = true;
      this.store.dispatch(getAttendances({
        page: this.pager.page + 1,
        perPage: this.pager.perPage,
        search: this.searchString,
        params: this.searchParams
      }));
    }
  }

}
