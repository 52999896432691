import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateMessageObject } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, Attendance, Channel, Contact, ContactEditComponent, MessageHelper, MetadataMessage } from 'lib-trend-core';

@Component({
  selector: 'app-chat-select-template',
  templateUrl: './chat-select-template.component.html',
  styleUrl: './chat-select-template.component.scss'
})
export class ChatSelectTemplateComponent extends AbstractComponent implements OnInit, OnDestroy {

  step = 1;
  selectedContact: Contact = null;
  contacts = new Array<any>();

  selectedTemplate: TemplateMessageObject = undefined;
  selectedChannel: Channel;
  templateMessage: MetadataMessage;
  variables: Record<string, string> = {};

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ContactEditComponent>,
    @Inject(MAT_DIALOG_DATA) public attendance: Attendance
  ) {
    super(injector);
    this.selectedChannel = attendance.channel;
    this.selectedContact = attendance.contact;
    this.buildVariables();
  }

  ngOnInit(): void {
  }

  onSelectTemplate(template: TemplateMessageObject) {
    this.selectedTemplate = template;
    this.nextStep();
  }

  setTemplateMessage(templateMessage: MetadataMessage) {
    this.templateMessage = templateMessage;
  }

  previousStep() {
    this.step--;
  }

  nextStep() {
    this.step++;
  }

  close() {
    this.dialogRef.close();
  }

  private buildVariables(): void {
    this.variables = {
      'Saudação': MessageHelper.getGreeting(),
      'Nome do Contato': this.selectedContact.name,
    };
    if (!!this.attendance?.user) {
      this.variables['Nome do Atendente'] = this.attendance.user.name;
    }
    if (!!this.attendance?.department) {
      this.variables['Departamento'] = this.attendance.department.name;
    }
    if (!!this.attendance.protocol) {
      this.variables['Protocolo'] = this.attendance.protocol;
    }
  }

  selectTemplateMessage() {
    const headerComponent = this.selectedTemplate.components.find(comp => comp.type === 'HEADER') as any;
    const bodyComponent = this.selectedTemplate.components.find(comp => comp.type === 'BODY');
    if (headerComponent?.format && ['IMAGE', 'VIDEO', 'DOCUMENT'].includes(headerComponent.format) && !this.templateMessage.headerParams?.[0]) {
      this.alertService.info('Por favor, insira o arquivo requerido para o template.');
      this.loading = false;
      return;
    }

    if (bodyComponent && this.templateMessage.bodyParams?.some(param => !param?.toString().trim())) {
      this.alertService.info('Por favor, preencha todos os parâmetros do corpo da mensagem.');
      this.loading = false;
      return;
    }

    this.dialogRef.close(<MetadataMessage>{
      ...this.templateMessage
    });
  }
}
