import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { CrmPipedriveComponent } from './crm-pipedrive.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    CrmPipedriveComponent,
  ],
  exports: [
    CrmPipedriveComponent,
  ],
  providers: [
  ]
})

export class CrmAttendanceModule { }
