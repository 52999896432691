import { Component, ElementRef, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TemplateMessageObject, TrendCloudAPIService } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, AlertModalComponent, Attendance, AttendanceService, AttendanceStatusEnum, Channel, ChannelService, ChannelTypeEnum, Contact, ContactEditComponent, ContactService, CreateAttendanceFromTemplate, Department, DepartmentService, MessageHelper, MessageService, MetadataMessage, NewConversationService, Team, TeamService, User, WaTemplateComponent } from 'lib-trend-core';
import { takeUntil } from 'rxjs';
import { getAttendance } from '../../state/actions';
import { AppState } from '../../state/app.state';

@Component({
  selector: 'new-conversation',
  templateUrl: './new-conversation.component.html',
  styleUrl: './new-conversation.component.scss'
})
export class ContactNewConversationComponent extends AbstractComponent implements OnInit {
  @ViewChild('btnSendTemplate') btnSendTemplate: ElementRef<HTMLButtonElement>;
  @ViewChild('modalContainer') modalContainer: ElementRef;

  step = 1;
  contacts = new Array<any>();
  selectedContact: Contact = null;
  errorMessage = "";

  channels: Array<Channel>;
  selectedChannel: Channel;

  selectedTemplate: TemplateMessageObject = undefined;
  templateMessage: MetadataMessage = {
    headerParams: [],
    bodyParams: [],
    previewText: ''
  };

  execMessage = null;

  listTeam: Array<Team>;
  listDepartments: Array<Department>;

  selectedDepartment: Department;

  variables: Record<string, string> = {};

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ContactNewConversationComponent>,
    private store: Store<AppState>,
    private contactService: ContactService,
    private channelService: ChannelService,
    private attendanceService: AttendanceService,
    private dialog: MatDialog,
    public trendCloudAPIService: TrendCloudAPIService,
    private teamService: TeamService,
    private departmentService: DepartmentService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(injector);
    if (this.data?.initialStep) {
      this.step = this.data.initialStep;
    }
  }

  ngOnInit() {
    if (this.data?.contactId) {
      this.contactService.getById(this.data.contactId).subscribe({
        next: (contact: Contact) => {
          this.selectedContact = contact;
          this.step = this.data.initialStep || 1;
          this.loadInitialData();
        },
        error: (err) => this.alertService.error(err.error.message)
      });
    } else {
      this.loadInitialData();
    }
  }

  loadInitialData() {
    this.channelService.getList().subscribe(channels => {
      this.channels = channels;
    });

    if (this.isAttendant() || this.isSupervisor()) {
      this.getListTeams();
    } else {
      this.getListDepartments();
    }
  }

  setTemplateMessage(templateMessage: MetadataMessage) {
    this.templateMessage = templateMessage;
  }

  onContactSelected(contact: any) {
    this.selectedContact = contact;
    this.step = 2;
  }

  selectChannel(channel: Channel) {
    this.errorMessage = "";
    clearTimeout(this.execMessage);

    this.attendanceService.existsContactStatus(this.selectedContact._id, [AttendanceStatusEnum.PENDING,
    AttendanceStatusEnum.IN_PROGRESS, AttendanceStatusEnum.PAUSED, AttendanceStatusEnum.OVERTIME], channel._id).subscribe(alreadyExistsContact => {
      if (alreadyExistsContact) {
        this.dialog.open(AlertModalComponent, {
          data: { title: `Atenção, já existe atendimento para ${this.selectedContact.name} no canal ${channel.name}.`, message: 'Veja nos seus atendimentos ou inicie um novo atendimento por outro canal.' },
        });

        this.modalContainer.nativeElement.scrollTo({
          top: 0,
          behavior: 'smooth'
        });

        this.execMessage = setTimeout(() => {
          this.errorMessage = "";
        }, 6000);

        this.selectedChannel = null;
        return;
      } else {
        if (channel.type === ChannelTypeEnum.CLOUD_API) {
          this.selectedChannel = channel;
        } else {
          this.selectedChannel = channel;
          this.selectedTemplate = null;
          this.sendTemplateMessage();
        }
      }
    });
  }

  selectDepartment(department: Department) {
    this.selectedDepartment = department;
  }

  onSelectTemplate(template: TemplateMessageObject) {
    this.selectedTemplate = template;
    this.step = 4;
    this.buildVariables();
  }

  clearValuesToBack(): void {
    this.selectedChannel = null;
    this.selectedContact = null;
    this.selectedDepartment = null;
    this.selectedTemplate = null;
  }

  goToBack(): void {
    this.selectedTemplate = null;
    this.step = 2;
  }

  onClose() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  sendTemplateMessage() {
    if (this.selectedChannel.type === ChannelTypeEnum.CLOUD_API) {
      this.btnSendTemplate.nativeElement.disabled = true;
      this.loading = true;

      const headerComponent = this.selectedTemplate.components.find(comp => comp.type === 'HEADER') as any;
      const bodyComponent = this.selectedTemplate.components.find(comp => comp.type === 'BODY');

      if (headerComponent?.format && ['IMAGE', 'VIDEO', 'DOCUMENT'].includes(headerComponent.format) && !this.templateMessage.headerParams?.[0]) {
        this.alertService.info('Por favor, insira o arquivo requerido para o template.');
        this.loading = false;
        return;
      }

      if (bodyComponent && this.templateMessage.bodyParams?.some(param => !param?.toString().trim())) {
        this.alertService.info('Por favor, preencha todos os parâmetros do corpo da mensagem.');
        this.loading = false;
        return;
      }
    }

    const createAttendanceFromTemplate: CreateAttendanceFromTemplate = {
      idDepartment: this.selectedDepartment._id,
      idChannel: this.selectedChannel._id,
      idContact: this.selectedContact._id,
      template: this.selectedTemplate,
      headerParams: this.templateMessage.headerParams,
      bodyParams: this.templateMessage.bodyParams,
      previewText: this.templateMessage.previewText
    }

    this.attendanceService.createFromTemplate(createAttendanceFromTemplate)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (attendance: Attendance) => {
          this.store.dispatch(getAttendance({ idAttendance: attendance._id }));
        },
        complete: () => {
          this.dialogRef.close();
        },
        error: (err) => {
          this.btnSendTemplate.nativeElement.disabled = false;
          this.loading = false;
          this.alertService.error(err.error.message);
        },
      });
  }

  private getListTeams(): void {
    this.teamService.getList().subscribe({
      next: (list: Array<Team>) => {
        this.listTeam = list
          .filter((team: Team) => {
            const userId = this.getCurrentUserUser()._id;
            const isAgent = team.agents.some((agent: User) => agent._id === userId);
            const isSupervisor = team.supervisors.some((supervisor: User) => supervisor._id === userId);
            return isAgent || isSupervisor;
          })
          .filter((team: Team) => team.department && team.department.removed !== true);
      },
      error: () => this.alertService.error('Ops! Não foi possível trazer os times da sua empresa. Tente novamente mais tarde.'),
    });
  }

  private getListDepartments(): void {
    this.departmentService.getList().subscribe({
      next: (list: Array<Department>) => {
        this.listDepartments = list;
      },
      error: (err: Error) => this.alertService.error(err.message),
    });
  }

  private buildVariables(): void {
    this.variables = {
      'Saudação': MessageHelper.getGreeting(),
      'Nome do Contato': this.selectedContact.name,
      'Nome do Atendente': this.getNameCurrentUser(),
      'Departamento': this.selectedDepartment.name,
    };
  }

}
